import React from 'react';
import styled from 'styled-components';
import mneme from '../assets/mneme.png';
import "./FeaturedWorks.scss";
import snackify from '../assets/snackify.png';
import topnine from '../assets/topnine.png';


const FeaturedWorks = () => {

    return(
        <Holder>
        <h1>Projects</h1>
        <div className = "works">
            <div className = "first">
                <a href = {'https://mneme-flashcards.netlify.app/'}>
                <img src = {mneme} alt = "mNeme's website" className = "mneme"/>
                </a>
                <div className = "firsttext">
                <h2>mNeme</h2>
                <HD>React/Redux, Firebase, Node/Express, Material UI</HD>
                <TP>The innovative flashcard application that combines an intuitive design with advanced study techniques designed for every type of user.</TP>
                <a href = {'https://mneme-flashcards.netlify.app/'}>Try It Out</a>
                <a href = {'https://github.com/rrrbba/flashcards-fe'}>Code</a>
                </div>
            </div>
            <div className = "second">
                <a href = {'https://confident-bassi-f8497a.netlify.com/'}>
                <img src = {snackify} alt = "snackify's website" className = "snackify"/>
                </a>
                <div className = "secondtext">
                <h2>Snackify</h2>
                <HD>React/Redux, Node.JS/Express, SQLite3, Postgres</HD>
                <TP>The hassle free office snack subscription service and app that provides quality snack choices to satisfy everyone's needs on your team.</TP>
                <a href = {'https://confident-bassi-f8497a.netlify.com/'}>Try It Out</a>
                <a href = {'https://github.com/Build-Week-Snackify'}>Code</a>
                </div>
            </div>
            <div className = "third">
                <a href = {'https://mytnine.netlify.com/'}>
                <img src = {topnine} alt = "topnine's website" className = "topnine"/>
                </a>
                <div className = "thirdtext">
                <h2>My Top Nine</h2>
                <HD>React, Material UI, Context API</HD>
                <TP>A fresh twist on an old idea that allows users to select and share their favorite top nine items and hobbies, and connect with other users who share similar passions while discovering new interests.</TP>
                <a href = {'https://mytnine.netlify.com/'}>Try It Out</a>
                <a href = {'https://github.com/buildweek-my-top-nine/Frontend'}>Code</a>
                </div>
            </div>
        </div>
        </Holder>
    )


}


export default FeaturedWorks;

const Holder = styled.div`
    // border-bottom: 2px dashed black;
    margin-bottom: 7%;
    // padding: 10%;
    margin-top: 4%;
    
`
const HD = styled.h3`
    width: 100%;
    display: flex;
    justify-content: center;
`
const TP = styled.p`
    margin: auto;
    // font-size: 1rem;
    width: 70%;
    // height: 150px;
    // margin-bottom: 10%;
`