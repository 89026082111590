import React from 'react';
import styled from 'styled-components';
// import fontawesome from '@fortawesome/fontawesome';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import './Contact.scss'


const Contact = () => {


    return(
        <div className = "con">
            <h1>Interested in working together?</h1>
                <Para>You can email me at:</Para> 
                <Email>rayven.burns@gmail.com</Email>
                <Para>or connect with me on social media!</Para>
                <ul class="contact">

                
								<Anchor href="https://www.linkedin.com/in/rayven-burns/">LinkedIn</Anchor>
								<Anchor href="https://github.com/rrrbba">Github</Anchor>
                                <Anchor href="https://twitter.com/rayven_rrrbba">Twitter</Anchor>
							</ul>
							{/* <ul class="copyright">
								<Copy>© Rayven Burns 2019 </Copy>
							</ul> */}
        </div>
    )
}


export default Contact;

// const Icon = styled.li`
//     font-family: 'Font Awesome 5 Free';
//     list-style-type: none;

// `

const Anchor = styled.a`
    font-family: 'Font Awesome 5 Free';
    text-decoration: none;
    color: #FF1469;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    

`

// const Copy = styled.li`
//     list-style-type: none;
// `

const Para = styled.p`
    font-size: 1.5rem;
`

const Email = styled.p`
    font-size: 1.5rem;
    color: #FF1469;
`