import React from 'react';
import styled from 'styled-components';
import "./Header.scss";

const Header = () => {
   
    return(
        <Background>
        <div className = "border">
        <Large>RAYVEN BURNS</Large>
        <Small>FULL STACK WEB DEVELOPER</Small>
        </div>
        </Background>
    )
}

export default Header

const Background = styled.div`
    background-color: whitesmoke;
`
const Large = styled.h1`
    color: #FF1469;
    font-size: 6rem;
    margin-top: 10%;
    padding-top: 4%;
`
const Small = styled.h2`
    color: #FF1469;
    padding-bottom: 4%;
    font-size: 2rem;
    margin-bottom: 4%;
`