import React from 'react';
import './App.css';
import Header from './components/Header';
import About from './components/About';
// import styled from 'styled-components';
import Skills from './components/Skills';
import FeaturedWorks from './components/FeaturedWorks';
import Contact from './components/Contact';
import { Link, Route } from 'react-router-dom';
import styled from 'styled-components';
import './App.scss'

function App() {
  return (
    <div className="App">
           <div className = "nav">
          <Anc to = '/'>Home</Anc>
          <Anc to = '/about'>About Rayven</Anc>
          <Anc to = '/skills'>Skills</Anc>
          <Anc to = '/projects'>Projects</Anc>
          <Anc to = '/contact'>Contact Me</Anc>
          </div>
        <Route exact path = '/' component = {Header} />
        <Route exact path = '/about' component = {About} />
        <Route exact path = '/skills' component = {Skills} />
        <Route exact path = '/projects' component = {FeaturedWorks} />
        <Route exact path = '/contact' component = {Contact} />
        {/* <div className = "nav">
          <Link to = '/about'>About Rayven</Link>
          <Link to = '/skills'>Skills</Link>
          <Link to = '/projects'>Featured Works</Link>
          <Link to = '/contact'>Contact Me</Link>
      </div> */}
    {/* <Header/> */}
    {/* <About />
    <Skills />
    <FeaturedWorks />
    <Contact /> */}
      <ul className="copyright">
			  <Copy>© Rayven Burns 2019 </Copy>
			</ul>
    </div>
  ); 
}

export default App;

const Copy = styled.li`
    list-style-type: none;
    margin: auto;
    // margin-left: -15%;
`
const Anc = styled(Link)`
  text-decoration: none;
  margin-right: 5%;
  color: #FF1469;
  font-size: 1.4rem;
  margin-left: 2%;
  font-weight: bold;
` 