import React from 'react';
import styled from 'styled-components';
import './Skills.scss'


const Skills = () => {

    return(
    <Holder>
        <Title>Skills</Title>

        <List>
            <div>
            <p>Javascript</p>
            <p>CSS</p>
            <p>LESS/SASS</p>
            <p>HTML</p>
            {/* </div>
            <div> */}
            <p>React.JS</p>
            <p>Redux</p>
            <p>Git</p>
            <p>Node.JS</p>
            {/* </div> */}
            <p>Express</p>
            <p>SQL</p>
            <p>Python</p>
            </div>
        </List>

     </Holder>
    )
}

export default Skills;

const List = styled.div`
    // display: flex;
    // justify-content: space-between;
    font-size: 2.5rem;
    // flex-flow: wrap;
    width: 60%;
    text-align: center
    flex-wrap: wrap;
    margin: 4rem auto;
    // margin-left: 25%;
    color: #FF1469;
    // margin-right: 13%;
    flex-flow: row;
`

const Holder = styled.div`
    margin-bottom: 10%;
    max-width: 100%;
   
`

const Title = styled.h1`
    justify-content: center;
`