import React from 'react';
import styled from 'styled-components';
import profilesmall from '../assets/profilesmall.jpeg';
import './About.scss';

const About = () => {
    
    return(
        <div className = "about">
            <img src = {profilesmall} alt = "Rayven" className = "image"/>
            <div className = "paraholder">
            <Para>Rayven is a web developer from Tennessee with a passion for creating practical applications for the user, while also providing an elegant UI and clean code. 
            <div>She received her bachelor’s in Animal Science from the University of Tennessee, Knoxville in 2018. Upon graduating she decided to turn a hobby of learning to code into a career by attending <Anchor href = {"https://lambdaschool.com/courses/full-stack-web-development"}>Lambda School </Anchor> to study web development.</div>
            <div> When she's not coding, she enjoys traveling, playing video games and spending time with her dog, Raijin.</div>
            </Para>
            </div>
        </div>
    )
}

export default About;


const Para = styled.p`
    box-sizing: border-box;
    // margin-left: 35%;
    margin-right: 15%;
    // display: flex;
    // justify-content: center;
    // max-width: 60%;
    font-size: 1rem;
    margin-bottom: 20%;
    margin-top: 10%;
    border: 2px solid #FF1469;
    border-radius: 4px;
    padding: 2%;
    line-height: 2rem;
`
const Anchor = styled.a`
    text-decoration: none;
    color: black;
`